import { PageProps } from 'gatsby';
import React, { useEffect } from 'react';
import PageLoader from '../components/PageLoader';
import Seo from '../components/Seo';
import { Constrain } from '../components/Containers';
import { GeneralBody } from '../components/Containers';

export default function AccountPage(props: PageProps) {
	useEffect(() => {
		if (typeof window !== 'undefined') {
			const timeoutId = setTimeout(() => {
				window.location.href = `https://users.freemius.com/store/1492`;
			}, 3000);
			return () => {
				clearTimeout(timeoutId);
			};
		}
	}, []);
	return (
		<>
			<Seo title="WPEForm Customer Accounts Page" />
			<Constrain>
				<GeneralBody>
					<PageLoader>
						<h2>Redirecting you to freemius store</h2>
						<p>
							We use freemius to manage licensing. Redirecting you to our
							provider.
						</p>
						<p>
							If the redirection fails, kindly click on{' '}
							<a href="https://users.freemius.com/store/1492">This Link</a>.
						</p>
					</PageLoader>
				</GeneralBody>
			</Constrain>
		</>
	);
}
